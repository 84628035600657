import { NgModule } from '@angular/core'
import { RouterModule, Routes } from '@angular/router'
import { DisableGuardGuard } from '@guards/disable.guard'
import { AuthenticatedGuard } from '@guards/authenticated.guard'

const routes: Routes = [
   { path: '', redirectTo: '/stats', pathMatch: 'full' },

   {
      path: 'requests',
      loadChildren: () => import('./core/pages/request/request.module').then(m => m.RequestModule),
      canActivate: [AuthenticatedGuard],
   },
   {
      path: 'stats',
      loadChildren: () => import('./core/pages/stats/stats.module').then(m => m.StatsModule),
      canActivate: [AuthenticatedGuard],
   },
   {
      path: 'usuarios',
      loadChildren: () =>
         import('./core/pages/usuarios/usuarios.module').then(m => m.UsuariosModule),
      canActivate: [AuthenticatedGuard],
   },
   {
      path: 'anuncios',
      loadChildren: () =>
         import('./core/pages/anuncios/anuncios.module').then(m => m.AnunciosModule),
      canActivate: [AuthenticatedGuard],
   },
   {
      path: 'vendas',
      loadChildren: () => import('./core/pages/vendas/vendas.module').then(m => m.VendasModule),
      canActivate: [AuthenticatedGuard],
   },
   {
      path: 'usuario-edit',
      loadChildren: () =>
         import('./core/pages/usuario-edit/usuario-edit.module').then(m => m.UsuarioEditModule),
      canActivate: [AuthenticatedGuard],
   },
   {
      path: 'venda-edit',
      loadChildren: () =>
         import('./core/pages/venda-edit/venda-edit.module').then(m => m.VendaEditModule),
      canActivate: [AuthenticatedGuard],
   },
   {
      path: 'anuncios-edit',
      loadChildren: () =>
         import('./core/pages/anuncios-edit/anuncios-edit.module').then(m => m.AnunciosEditModule),
      canActivate: [AuthenticatedGuard],
   },
   {
      path: 'login',
      loadChildren: () => import('./core/pages/auth/auth.module').then(m => m.AuthModule),
      canActivate: [DisableGuardGuard],
   },
   {
      path: 'novo-usuario',
      loadChildren: () =>
         import('./core/pages/new-user/new-user.module').then(m => m.NewUserModule),
      canActivate: [AuthenticatedGuard],
   },
   {
      path: 'faq',
      loadChildren: () => import('./core/pages/faq/faq.module').then(m => m.FaqModule),
      canActivate: [AuthenticatedGuard],
   },
   {
      path: 'questions',
      loadChildren: () =>
         import('./core/pages/questions/questions.module').then(m => m.QuestionsModule),
      canActivate: [AuthenticatedGuard],
   },
   { path: '**', redirectTo: '/home' },
]

@NgModule({
   imports: [RouterModule.forRoot(routes)],
   exports: [RouterModule],
})
export class AppRoutingModule {}
