<section class="generic-table">
   <table class="table">
      <thead class="table__head">
         <tr class="table__head-tr">
            <ng-content select="[head]"></ng-content>
         </tr>
      </thead>
      <tbody class="table__body">
         <ng-content select="[body]"></ng-content>
      </tbody>
   </table>
</section>

<!-- <div class="pagination">
   <button>Ant</button>
   <ul>
      <li *ngFor="let page of [1, 2, 3, 4]" [class.current]="currentPage === page">{{ page }}</li>
   </ul>
   <button>Prox</button>
</div> -->
