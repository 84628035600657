import { Pipe, PipeTransform } from '@angular/core'

@Pipe({
   name: 'urlChanges',
})
export class UrlChangesPipe implements PipeTransform {
   transform(value: any, type: string): string {
      let urlImg: string

      switch (type) {
         case 'product':
            urlImg = value?.product_img
            break
         case 'withdraw':
            urlImg = value?.store?.profilePhoto
            break
         case 'shipping':
            urlImg = ''
            break

         default:
            'store'
            urlImg = value?.user?.profilePhoto
            break
      }
      return urlImg
   }
}
