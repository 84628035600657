import { OverlayModule } from '@angular/cdk/overlay'
import { CommonModule } from '@angular/common'
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core'
import { ReactiveFormsModule } from '@angular/forms'
import { MatSnackBarModule, MAT_SNACK_BAR_DEFAULT_OPTIONS } from '@angular/material/snack-bar'
import { MatDatepickerModule } from '@angular/material/datepicker'
import { RouterModule } from '@angular/router'
import { FilterPipe } from '@pipes/filter.pipe'
import { HttpSafeImagePipe } from '@pipes/http-safe-image.pipe'
import { SafePipe } from '@pipes/safe.pipe'
import { StatusPipe } from '@pipes/status.pipe'
import player from 'lottie-web'
import { CurrencyMaskModule } from 'ng2-currency-mask'
import { InfiniteScrollModule } from 'ngx-infinite-scroll'
import { LottieModule } from 'ngx-lottie'
import { IConfig, NgxMaskModule } from 'ngx-mask'
import { NgxPaginationModule } from 'ngx-pagination'
import { AlertsComponent } from './components/alerts/alerts.component'
import { ArrowAnimatedComponent } from './components/arrow-animated/arrow-animated.component'
import { BaseDialogComponent } from './components/base-dialog/base-dialog.component'
import { CardComponent } from './components/card/card.component'
import { CategoryCardsComponent } from './components/category-cards/category-cards.component'
import { DeleteConfirmComponent } from './components/delete-confirm/delete-confirm.component'
import { DragDropFileComponent } from './components/drag-drop-file/drag-drop-file.component'
import { ErrorMessageComponent } from './components/error-message/error-message.component'
import { InputsComponent } from './components/inputs/inputs.component'
import { LoadingSpinComponent } from './components/loading-spin/loading-spin.component'
import { LoadingComponent } from './components/loading/loading.component'
import { NotFoundMessageComponent } from './components/not-found-message/not-found-message.component'
import { OptionsMenuComponent } from './components/options-menu/options-menu.component'
import { OptionsMenuDirective } from './components/options-menu/options-menu.directive'
import { OrdenationMenuComponent } from './components/ordenation-menu/ordenation-menu.component'
import { ReasonRejectionComponent } from './components/reason-rejection/reason-rejection.component'
import { SearchInputComponent } from './components/search-input/search-input.component'
import { SidebarStructureComponent } from './components/sidebar-structure/sidebar-structure.component'
import { SidemenuComponent } from './components/sidemenu/sidemenu.component'
import { TableComponent } from './components/table/table.component'
import { ToastComponent } from './components/toast/toast.component'
import { TransactionsTableComponent } from './components/transactions-table/transactions-table.component'
import { DropzoneDirective } from './directives/dropzone.directive'
import { TooltipModule } from './directives/tooltip/tooltip.module'
import { PaginationComponent } from './pagination/pagination.component'
import { BorderColorPipe } from './pipes/border-color.pipe'
import { CapitalizeCasePipe } from './pipes/capitalize-case.pipe'
import { EllipsisPipe } from './pipes/ellipsis.pipe'
import { IconPipe } from './pipes/icon.pipe'
import { SituationPipe } from './pipes/situation.pipe'
import { UrlChangesPipe } from './pipes/url-changes.pipe'
import { GenericConfirmComponent } from './components/generic-confirm/generic-confirm.component'
import { MatFormFieldModule } from '@angular/material/form-field'
import { MatInputModule } from '@angular/material/input'
import { MatNativeDateModule } from '@angular/material/core'
const maskConfig: Partial<IConfig> = {
   validation: false,
}

export function playerFactory() {
   return player
}
@NgModule({
   declarations: [
      SidemenuComponent,
      CardComponent,
      EllipsisPipe,
      CapitalizeCasePipe,
      SearchInputComponent,
      ArrowAnimatedComponent,
      OptionsMenuDirective,
      OptionsMenuComponent,
      TableComponent,
      FilterPipe,
      SituationPipe,
      IconPipe,
      BaseDialogComponent,
      DeleteConfirmComponent,
      SidebarStructureComponent,
      InputsComponent,
      ErrorMessageComponent,
      DragDropFileComponent,
      AlertsComponent,
      SafePipe,
      CategoryCardsComponent,
      DropzoneDirective,
      LoadingComponent,
      ToastComponent,
      PaginationComponent,
      LoadingSpinComponent,
      ReasonRejectionComponent,
      TransactionsTableComponent,
      BorderColorPipe,
      OrdenationMenuComponent,
      UrlChangesPipe,
      NotFoundMessageComponent,
      StatusPipe,
      HttpSafeImagePipe,
      GenericConfirmComponent,
   ],
   imports: [
      CommonModule,
      RouterModule,
      ReactiveFormsModule,
      OverlayModule,
      NgxPaginationModule,
      NgxMaskModule.forRoot(maskConfig),
      LottieModule.forRoot({ player: playerFactory }),
      TooltipModule,
      MatSnackBarModule,
      InfiniteScrollModule,
      CurrencyMaskModule,
      MatDatepickerModule,
      MatFormFieldModule,
      MatInputModule,
      MatNativeDateModule,
   ],
   exports: [
      SidemenuComponent,
      RouterModule,
      CardComponent,
      EllipsisPipe,
      CapitalizeCasePipe,
      SearchInputComponent,
      ArrowAnimatedComponent,
      OptionsMenuDirective,
      OptionsMenuComponent,
      TableComponent,
      NgxMaskModule,
      FilterPipe,
      SituationPipe,
      IconPipe,
      BaseDialogComponent,
      DeleteConfirmComponent,
      SidebarStructureComponent,
      InputsComponent,
      ErrorMessageComponent,
      DragDropFileComponent,
      AlertsComponent,
      SafePipe,
      CategoryCardsComponent,
      DropzoneDirective,
      LoadingComponent,
      NgxPaginationModule,
      TooltipModule,
      ToastComponent,
      MatSnackBarModule,
      PaginationComponent,
      InfiniteScrollModule,
      LoadingSpinComponent,
      ReasonRejectionComponent,
      CurrencyMaskModule,
      TransactionsTableComponent,
      BorderColorPipe,
      OrdenationMenuComponent,
      NotFoundMessageComponent,
      StatusPipe,
      HttpSafeImagePipe,
      MatDatepickerModule,
      MatFormFieldModule,
      MatInputModule,
      MatNativeDateModule,
      ReactiveFormsModule,
   ],
   providers: [{ provide: MAT_SNACK_BAR_DEFAULT_OPTIONS, useValue: { duration: 5000 } }],
   schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class SharedModule {}
