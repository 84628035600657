<nav class="side-bar">
   <img
      class="side-bar__logo"
      src="assets/imgs/white-logo.svg"
      alt="Logo moedas.com.br"
      aria-label="Logo moedas.com.br"
   />

   <ul class="side-bar__menu">
      <ng-container *ngFor="let route of routers">
         <li [routerLink]="route.rota" routerLinkActive="active">
            <span [class]="route.icon"></span>
            <p>{{ route.title }}</p>
         </li>
      </ng-container>
   </ul>

   <div class="side-bar__footer">
      <ng-container *ngIf="userDataService.userData$ | async as data">
         <span class="side-bar__footer-user">
            <span class="icon-icon-material-account-circle"></span>
            <p>{{ data?.name | capitalizecase }}</p>
         </span>
         <p class="side-bar__footer-status">
            Logado pela última vez em {{ data?.lastLogin | date: 'dd/MM' }} às
            {{ data?.lastLogin | date: 'HH' }}h{{ data?.lastLogin | date: 'mm' }}
         </p>
      </ng-container>

      <button class="side-bar__footer-logout" (click)="logOut()" id="logout">
         <span class="icon-exit"></span> Sair
      </button>
   </div>
</nav>
