import { Component, Inject, OnInit } from '@angular/core'
import { FormControl, FormGroup, Validators } from '@angular/forms'
import { DialogRef } from '@shared/services/dialog/dialog-refs.helper'
import { DIALOG_DATA } from '@shared/services/dialog/modal.service'

@Component({
   selector: 'app-reason-rejection',
   templateUrl: './reason-rejection.component.html',
   styleUrls: ['./reason-rejection.component.scss'],
})
export class ReasonRejectionComponent implements OnInit {
   public formInput: FormGroup
   public submitted: boolean = false
   constructor(private dialogRef: DialogRef, @Inject(DIALOG_DATA) public data: any) {}

   ngOnInit() {
      this.formInput = new FormGroup({
         reason: new FormControl('', Validators.required),
      })
   }

   sendReason() {
      this.dialogRef.close(this.formInput.value.reason)
   }
}
