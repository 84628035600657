import { Component, ViewEncapsulation } from '@angular/core'

@Component({
   selector: 'moedas-table',
   templateUrl: './table.component.html',
   styleUrls: ['./table.component.scss'],
   encapsulation: ViewEncapsulation.None,
})
export class TableComponent {
   public currentPage: number = 1
   constructor() {}
}
