import { Component, EventEmitter, Input, Output, ViewEncapsulation } from '@angular/core'

@Component({
   selector: 'pagination',
   templateUrl: './pagination.component.html',
   styleUrls: ['./pagination.component.scss'],
   encapsulation: ViewEncapsulation.None,
})
export class PaginationComponent {
   @Output() page = new EventEmitter<number>()
   @Input() id: string
   constructor() {}
}
