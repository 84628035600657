/* eslint-disable complexity */
import { Pipe, PipeTransform } from '@angular/core'

@Pipe({
   name: 'situation',
})
export class SituationPipe implements PipeTransform {
   transform(value: string, type: string = 'user'): string {
      let realValue: string
      const typeString: string = type === 'user' ? 'Usuário' : type === 'false' ? '' : 'Produto'
      switch (value) {
         case 'awaitingApproval':
            realValue = 'Aprovação pendente'
            break
         case 'active':
            realValue = `${typeString} ativo`
            break
         case 'inactive':
            realValue = `${typeString} inativo`
            break
         case 'blocked':
            realValue = `${typeString} bloqueado`
            break
         case 'suspended':
            realValue = 'Suspenso'
            break
         case 'finished':
            realValue = 'Encerrado'
            break
         case 'disapproved':
            realValue = 'Rejeitado'
            break
         case 'failed':
            realValue = 'Falhou'
            break
         case 'paused':
            realValue = 'Pausado'
            break
         case 'waiting':
            realValue = 'Em processamento'
            break
         case 'success':
            realValue = 'Sucesso'
            break

         default:
            'unknown'
            realValue = 'Status desconhecido'
            break
      }
      return realValue
   }
}
