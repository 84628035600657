/* istanbul ignore file */
import { Overlay } from '@angular/cdk/overlay'
import { ComponentPortal } from '@angular/cdk/portal'
import {
   ComponentRef,
   Directive,
   ElementRef,
   EventEmitter,
   HostListener,
   Injector,
   Input,
   Output,
   TemplateRef,
} from '@angular/core'
import { Placement } from '@enums/placementOverlay.enum'
import { OptionsMenuComponent } from './options-menu.component'

@Directive({
   selector: '[menu]',
})
export class OptionsMenuDirective {
   @Input('menu') content: TemplateRef<any>
   @Input() overlayPlacement: Placement = 'top'
   @Output() toggle = new EventEmitter<boolean>()
   constructor(private overlay: Overlay, private injector: Injector, private element: ElementRef) {}

   @HostListener('click', ['$event.target'])
   click(event: any) {
      this.toggle.emit()
      const overlayRef = this.overlay.create({
         hasBackdrop: true,
         backdropClass: 'menu-overlay',
         scrollStrategy: this.overlay.scrollStrategies.close(),
         panelClass: 'menu',
         positionStrategy: this.overlay
            .position()
            .flexibleConnectedTo(event)
            .withPositions([
               {
                  originX: 'start',
                  originY: 'bottom',
                  overlayX: 'start',
                  overlayY: 'top',
                  offsetY: 12,
                  offsetX: -15,
               },
            ]),
      })

      const component = new ComponentPortal(OptionsMenuComponent, null)
      const ref: ComponentRef<OptionsMenuComponent> = overlayRef.attach(component)
      ref.instance.content = this.content
      ref.instance.setClose.subscribe(_ => {
         overlayRef.dispose()
         this.toggle.emit()
      })
      overlayRef.backdropClick().subscribe(_ => {
         overlayRef.dispose()
         this.toggle.emit()
      })
   }
}
