import { registerLocaleData } from '@angular/common'
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http'
import localePt from '@angular/common/locales/pt'
import { LOCALE_ID, NgModule } from '@angular/core'
import { BrowserModule } from '@angular/platform-browser'
import { BrowserAnimationsModule } from '@angular/platform-browser/animations'
import { AuthInterceptor } from '@services/interceptors/auth.interceptor'
import { LoadingInterceptor } from '@services/interceptors/loading.interceptor'
import { AppRoutingModule } from './app-routing.module'
import { AppComponent } from './app.component'
import { SharedModule } from './shared/shared.module'

registerLocaleData(localePt)
@NgModule({
   declarations: [AppComponent],
   imports: [
      BrowserModule,
      AppRoutingModule,
      BrowserAnimationsModule,
      SharedModule,
      HttpClientModule,
   ],
   providers: [
      {
         provide: HTTP_INTERCEPTORS,
         useClass: LoadingInterceptor,
         multi: true,
      },
      {
         provide: LOCALE_ID,
         useValue: 'pt-BR',
      },
      {
         provide: HTTP_INTERCEPTORS,
         useClass: AuthInterceptor,
         multi: true,
      },
   ],
   bootstrap: [AppComponent],
})
export class AppModule {}
