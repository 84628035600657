import { Injectable } from '@angular/core'
import { CanActivate, Router, UrlTree, ActivatedRouteSnapshot } from '@angular/router'
import { ELocalStorage } from '@enums/localstorage.enum'
import { Observable } from 'rxjs'

@Injectable({
   providedIn: 'root',
})
export class AuthenticatedGuard implements CanActivate {
   constructor(private router: Router) { }
   canActivate(
      route: ActivatedRouteSnapshot,
   ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
      let itemStr: any = localStorage.getItem(ELocalStorage.USER_TOKEN)
      const item = JSON.parse(itemStr)
      const now = new Date()

      if (!item) {
         this.router.navigate(['login'])
         return false
      }
      if (now.getTime() > item.expiry || !item.expiry) {
         localStorage.clear()
         this.router.navigate(['login'])
         return false
      }

      if (this.router.url != '/login') {
         const userDataStr: any = localStorage.getItem(ELocalStorage.USER_DATA)
         const userData = JSON.parse(userDataStr)
         const allAccess = userData.scopes?.find(
            (e: string) => e === route.routeConfig?.path || e === '*',
         )

         if (!allAccess) {
            return false
         }
      }

      return true
   }
}
