import { Overlay } from '@angular/cdk/overlay'
import { ComponentPortal, ComponentType } from '@angular/cdk/portal'
import { Injectable, InjectionToken, Injector } from '@angular/core'
import { DialogRef } from './dialog-refs.helper'

export const DIALOG_DATA = new InjectionToken<any>('DIALOG_DATA')

export interface DialogConfig {
   backdropClass: string
   data?: any
   disableClose: boolean
   panelClass: string
}

const defaultConfig: DialogConfig = {
   backdropClass: '',
   disableClose: false,
   panelClass: '',
}

@Injectable({
   providedIn: 'root',
})
export class ModalService {
   constructor(private overlay: Overlay, private injector: Injector) {}

   open(component: ComponentType<any>, config: Partial<DialogConfig> = {}) {
      const dialogConfig: DialogConfig = Object.assign({}, defaultConfig, config)

      const overlayRef = this.overlay.create({
         hasBackdrop: true,
         positionStrategy: this.overlay.position().global(),
         scrollStrategy: this.overlay.scrollStrategies.block(),
         panelClass: ['custom-modal', dialogConfig.panelClass],
         backdropClass: 'background-modal',
      })
      const dialogRef = new DialogRef(overlayRef, dialogConfig)
      const injector = Injector.create({
         parent: this.injector,
         providers: [
            { provide: DialogRef, useValue: dialogRef },
            { provide: DIALOG_DATA, useValue: config?.data },
         ],
      })

      const portal = new ComponentPortal(component, null, injector)
      overlayRef.attach(portal)
      return dialogRef
   }
}
