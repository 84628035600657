import { Injectable } from '@angular/core'
import { HttpRequest, HttpHandler, HttpEvent } from '@angular/common/http'
import { Observable } from 'rxjs'
import { LoadingService } from '@shared/services/dialog/loading.service'
import { delay, finalize } from 'rxjs/operators'
import { HAS_LOADING } from '@helpers/constants/contexts.helper'

@Injectable({
   providedIn: 'root',
})
export class LoadingInterceptor {
   activeRequests: number = 0
   constructor(private loadingService: LoadingService) {}

   intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
      if (request.context.get(HAS_LOADING)) {
         if (this.activeRequests === 0) {
            this.loadingService.setLoading(true)
         }
         this.activeRequests++
         return next.handle(request).pipe(
            delay(2000),
            finalize(() => {
               this.activeRequests--
               if (this.activeRequests === 0) {
                  this.loadingService.setLoading(false)
               }
            }),
         )
      }

      return next.handle(request)
   }
}
