import { Injectable } from '@angular/core'
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http'
import { Observable } from 'rxjs'
import { environment } from '@environment'
import { ELocalStorage } from '@enums/localstorage.enum'
@Injectable()
export class AuthInterceptor implements HttpInterceptor {
   private token_header_key: string = 'Authorization'
   readonly api_url: string = environment.apiUrl
   constructor() {}

   intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
      let token

      token = localStorage.getItem(ELocalStorage.USER_TOKEN)

      if (!!token && request.url.includes(this.api_url)) {
         request = request.clone({
            headers: request.headers.set(
               this.token_header_key,
               'Bearer ' + JSON.parse(token).token,
            ),
         })
      }
      return next.handle(request)
   }
}
