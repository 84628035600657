import { Component, OnInit } from '@angular/core'
import { Router } from '@angular/router'
import { UserDataService } from '@shared/services/userdata.service'
import { navMenuItems } from './routes'
import { ELocalStorage } from '@enums/localstorage.enum'

@Component({
   selector: 'sidemenu',
   templateUrl: './sidemenu.component.html',
   styleUrls: ['./sidemenu.component.scss'],
})
export class SidemenuComponent implements OnInit {
   public routers: any = []
   constructor(private router: Router, public userDataService: UserDataService) { }

   ngOnInit(): void {
      const scopes = this.getScopes()

      for (let item of navMenuItems) {
         let findScope = scopes.find((e: any) => e === item.rota || e === '*')
         if (findScope === '*') {
            this.routers = navMenuItems
         } else if (findScope && findScope !== '*') {
            this.routers.push(item)
         }
      }
   }

   getScopes() {
      const userDataStr: any = localStorage.getItem(ELocalStorage.USER_DATA)
      const userData = JSON.parse(userDataStr)

      return userData.scopes
   }

   logOut() {
      localStorage.clear()
      this.router.navigate(['login'])
   }
}
