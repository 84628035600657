import { Location } from '@angular/common'
import { Component, OnDestroy, OnInit } from '@angular/core'
import { Router } from '@angular/router'
import { Subscription } from 'rxjs'
import { map } from 'rxjs/operators'
@Component({
   selector: 'app-root',
   templateUrl: './app.component.html',
   styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy {
   public titleText: string
   public subscriptions: Subscription[] = []
   public showTitle: boolean = true
   public showSideBar: boolean = true
   constructor(private location: Location, private router: Router) { }

   /* istanbul ignore next */
   ngOnInit(): void {
      this.subscriptions.push(
         this.router.events
            .pipe(
               map(_ => {
                  const location: string = this.location.path()
                  const texts: { [key: string]: string } = {
                     request: 'Requisições',
                     stats: 'Dashboard',
                     usuarios: 'Usuários',
                     anuncios: 'Anuncios',
                     pagamentos: 'Pagamentos',
                     'faq/perguntas': 'Perguntas',
                     'faq/secoes': 'Seções',
                  }
                  this.titleText = texts[location.replace('/', '')]
                  const subrouters: any[] = location.split('/')
                  this.showTitle =
                     subrouters.includes('usuario-edit') ||
                     subrouters.includes('anuncios-edit') ||
                     subrouters.includes('login')

                  this.showSideBar = !subrouters.includes('login')
               }),
            )
            .subscribe(),
      )
   }

   /* istanbul ignore next */
   ngOnDestroy() {
      this.subscriptions.forEach(s => s.unsubscribe())
   }
}
