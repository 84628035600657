import { Injectable } from '@angular/core'
import { Router, CanActivate, UrlTree } from '@angular/router'
import { Observable } from 'rxjs'
import { ELocalStorage } from '@enums/localstorage.enum'

@Injectable({
   providedIn: 'root',
})
export class DisableGuardGuard implements CanActivate {
   constructor(private router: Router) {}
   canActivate(): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
      let token: string | null | undefined

      token = localStorage.getItem(ELocalStorage.USER_TOKEN)
      if (!token) {
         return true
      } else {
         this.router.navigate(['home'])
      }

      return false
   }
}
