<section class="sidebar-structure">
   <div class="sidebar-structure__side-item-page">
      <a (click)="location.back()">
         <span class="icon-arrow-left"></span>
         Voltar
      </a>

      <p>{{ title }}</p>
   </div>
   <article class="sidebar-structure__container">
      <aside class="sidebar-structure__side-item">
         <ng-content select="[aside]"></ng-content>
      </aside>
      <div class="sidebar-structure__container-content">
         <ng-content select="[content]"></ng-content>
      </div>
   </article>
</section>
