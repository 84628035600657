import { Component, Inject, ViewEncapsulation } from '@angular/core'
import { DialogRef } from '@shared/services/dialog/dialog-refs.helper'
import { DIALOG_DATA } from '@shared/services/dialog/modal.service'

@Component({
   selector: 'app-generic-confirm',
   templateUrl: './generic-confirm.component.html',
   styleUrls: ['./generic-confirm.component.scss'],
   encapsulation: ViewEncapsulation.None,
})
export class GenericConfirmComponent {
   constructor(public dialogRef: DialogRef, @Inject(DIALOG_DATA) public data: any) {}
}
