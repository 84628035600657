<ng-container *ngIf="input_type === 'input'">
   <div class="input-wrapper">
      <span class="input-wrapper__label-icon">
         <label [attr.for]="label">{{ label }}</label>
         <i class="material-icons"> {{ icon }} </i>
      </span>
      <input
         #input
         [type]="type"
         [maxLength]="maxlength"
         [attr.aria-placeholder]="placeholder"
         [class.invalid]="(submitted && control.errors) || !!setErros"
         [placeholder]="placeholder"
         [formControl]="control"
         [readonly]="readonly"
         [class.readonly]="readonly"
         [mask]="i_mask"
         [thousandSeparator]="thousandSeparator"
         [prefix]="prefix"
         (focusout)="focusOut.emit($event)"
         (focus)="attrConf(input)"
      />
      <span *ngIf="loading" class="input-wrapper__loading"></span>
      <span class="input-wrapper__msgs">
         <error-message *ngIf="(submitted && control.errors) || !!setErros" [control]="control">
         </error-message>
         <small *ngIf="!!hint">{{ hint }}</small>
      </span>
   </div>
</ng-container>

<ng-container *ngIf="input_type === 'input-currency'">
   <div class="input-wrapper">
      <span class="input-wrapper__label-icon">
         <label [attr.for]="label">{{ label }}</label>
      </span>
      <input
         #input
         type="text"
         maxlength="19"
         [attr.aria-placeholder]="placeholder"
         [class.invalid]="(submitted && control.errors) || !!setErros"
         [placeholder]="placeholder"
         [formControl]="control"
         [readonly]="readonly"
         [class.readonly]="readonly"
         currencyMask
         [options]="options"
         (focusout)="focusOut.emit($event)"
         (focus)="attrConf(input)"
      />
      <span class="input-wrapper__msgs">
         <error-message *ngIf="(submitted && control.errors) || !!setErros" [control]="control">
         </error-message>
      </span>
   </div>
</ng-container>

<ng-container *ngIf="input_type === 'password-valid'">
   <div class="input-wrapper">
      <span class="input-wrapper__label-icon">
         <label [attr.for]="label">{{ label }}</label>
         <span
            [class]="'icon-' + icon"
            [tooltip]="passwordDesc"
            [tooltipStyles]="{ width: '330px' }"
         ></span>
      </span>
      <input
         #input
         type="password"
         [maxLength]="maxlength"
         [attr.aria-placeholder]="placeholder"
         [class.invalid]="submitted && control.errors"
         [placeholder]="placeholder"
         [readonly]="readonly"
         [class.readonly]="readonly"
         [formControl]="control"
         (focus)="attrConf(input)"
      />
      <span class="input-wrapper__msgs">
         <error-message
            *ngIf="control.errors && control.dirty"
            [control]="control"
            [msgs]="errorMessageResource"
         >
         </error-message>
         <div class="input-wrapper__msgs-password">
            <span
               *ngFor="let _ of [].constructor(3); let i = index"
               class="{{
                  'input-wrapper__msgs-password-step ' +
                     (passwordStrength >= i + 1 && !!control.value
                        ? 'input-wrapper__msgs-password-step--' + (passwordStrength || 1)
                        : '')
               }}"
               id="progress-bar"
            ></span>
            <p>{{ ['Baixa', 'Média', 'Alta'][passwordStrength - 1] }}</p>
         </div>
      </span>
   </div>
   <ng-template #passwordDesc>
      <ul>
         <li>Uma letra maiúscula</li>
         <li>Uma letra minúscula</li>
         <li>Um número</li>
         <li>Um caractere especial</li>
      </ul>
   </ng-template>
</ng-container>

<ng-container *ngIf="input_type === 'radio'">
   <span>
      <p class="radio-label">{{ label }}</p>
      <div class="input-wrapper__radio" [class.row]="type === 'row'">
         <ng-container *ngFor="let item of input_labels">
            <label class="radio">
               <input
                  type="radio"
                  class="radio__input"
                  [id]="item.label"
                  [value]="value"
                  [name]="label"
                  [checked]="item.checked"
                  [formControl]="control"
                  [value]="item.value"
                  [readonly]="readonly"
                  [class.readonly]="readonly"
               />
               <span>{{ item.label }}</span>
            </label>
         </ng-container>
      </div>
   </span>
</ng-container>

<ng-container *ngIf="input_type === 'select'">
   <div class="input-wrapper">
      <label [attr.for]="label">{{ label }}</label>
      <select [formControl]="control" required [class.invalid]="submitted && control.errors">
         <option value="" disabled selected hidden>{{ placeholder }}</option>
         <ng-content></ng-content>
      </select>
      <span class="input-wrapper__msgs">
         <error-message *ngIf="submitted && control.errors" [control]="control"> </error-message>
         <small *ngIf="!!hint">{{ hint }}</small>
      </span>
   </div>
</ng-container>

<ng-container *ngIf="input_type === 'textarea'">
   <div>
      <label [attr.for]="label">{{ label }}</label>
      <textarea
         type="text"
         [attr.aria-placeholder]="placeholder"
         [class.invalid]="(submitted && control.errors) || !!setErros"
         [placeholder]="placeholder"
         [formControl]="control"
         [readonly]="readonly"
         [class.readonly]="readonly"
         [rows]="rows"
      ></textarea>
      <error-message> </error-message>
   </div>
</ng-container>

<ng-container *ngIf="input_type === 'toggle'">
   <div class="toggle">
      <p (click)="toggle.click()">{{ label }}</p>
      <label class="slide-toggle">
         <input
            type="checkbox"
            [disabled]="readonly"
            [value]="value"
            [checked]="checked"
            [attr.aria-label]="label"
            #toggle
            (click)="toggleEvent.emit($event)"
         />
         <span class="slider"></span>
      </label>
      <error-message> </error-message>
   </div>
</ng-container>

<ng-container *ngIf="input_type === 'slider'">
   <div class="input-wrapper slider-wrapper">
      <label for="slider">{{ label }}</label>
      <div class="slider-wrapper__input">
         <!-- <input
            class="slider-wrapper__input-slide"
            [style.background]="
               'linear-gradient(to right, #1251ff 0%, #1251ff ' +
               valSum +
               '%, #fff ' +
               valSum +
               '%, white 100%)'
            "
            type="range"
            min="0"
            max="500"
            [value]="slideVal"
            [formControl]="control"
            (input)="setVal($event)"
         /> -->
         <input
            id="slider"
            class="slider-wrapper__input-num"
            type="number"
            min="0"
            max="500"
            readonly
            [value]="slideVal"
            [formControl]="control"
            [class.invalid]="(submitted && control.errors) || !!setErros"
         />
      </div>
      <span class="input-wrapper__msgs msg-error">
         <small *ngIf="(submitted && control.errors) || !!setErros"> Campo obrigatório </small>
      </span>
   </div>
</ng-container>
