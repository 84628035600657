import { HttpClient, HttpHeaders } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { environment } from '@environment'
import { Observable } from 'rxjs'

@Injectable({
   providedIn: 'root',
})
export class UploadService {
   private readonly apiUrl: string = environment.apiUrl
   constructor(private httpClient: HttpClient) {}

   public uploadFiles(data: any, type: string): Observable<any> {
      const headers = new HttpHeaders()
      return this.httpClient.post<any>(`${this.apiUrl}upload?type=${type}`, data, {
         headers,
         reportProgress: true,
         observe: 'events',
      })
   }
}
