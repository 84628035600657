/* istanbul ignore file */
import { Component, ElementRef, OnDestroy, OnInit } from '@angular/core'
import { LoadingService } from '@shared/services/dialog/loading.service'
import { AnimationOptions } from 'ngx-lottie'
import { Subscription } from 'rxjs'

@Component({
   selector: 'loading',
   templateUrl: './loading.component.html',
   styleUrls: ['./loading.component.scss'],
})
export class LoadingComponent implements OnInit, OnDestroy {
   public lottieConfig: AnimationOptions = {
      path: './../../../../assets/animations/loading-moedas.json',
      renderer: 'svg',
      loop: true,
      autoplay: true,
   }
   public subscriptions: Subscription[] = []
   public loading: boolean = false
   constructor(public loadingService: LoadingService, private element: ElementRef) {}

   ngOnInit() {
      this.subscriptions.push(
         this.loadingService.loading$.pipe().subscribe((status: boolean) => {
            this.loading = status
            if (status) {
               this.element.nativeElement.closest('body').style.overflow = 'hidden'
            } else {
               this.element.nativeElement.closest('body').style.overflow = 'auto'
            }
         }),
      )
   }

   ngOnDestroy() {
      this.subscriptions.forEach(s => s.unsubscribe())
   }
}
