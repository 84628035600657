import { Pipe, PipeTransform } from '@angular/core'
import { WindowSizeService } from '@helpers/utils/window-size.helper'

@Pipe({
   name: 'ellipsis',
})
export class EllipsisPipe implements PipeTransform {
   constructor(private windowSizeService: WindowSizeService) {}
   transform(value: string, args?: number, large?: number): string {
      this.windowSizeService.validateSize('max-width: 1500px')
      let realElipse: string = ''
      if (!args) {
         return value
      }
      if (!value?.length) {
         return value
      }

      /* istanbul ignore next */
      if (value.length > args) {
         if (!!large) {
            this.windowSizeService.valid_size$.subscribe(res => {
               if (res) {
                  realElipse = value.length >= args ? `${value.substring(0, args)}...` : value
               } else {
                  realElipse = value.length >= large ? `${value.substring(0, large)}...` : value
               }
            })
            return realElipse
         } else {
            return `${value.substring(0, args)}...`
         }
      }
      return value
   }
}
