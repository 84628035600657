import { Injectable } from '@angular/core'
import { ELocalStorage } from '@enums/localstorage.enum'
import { BehaviorSubject, Observable } from 'rxjs'

@Injectable({
   providedIn: 'root',
})
export class UserDataService {
   readonly storageData: any = JSON.parse(localStorage.getItem(ELocalStorage.USER_DATA) || '{}')
   private storageUser$: BehaviorSubject<any> = new BehaviorSubject<any>(this.storageData)
   readonly userData$: Observable<any> = this.storageUser$.asObservable()

   private _isAuth$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false)
   readonly isAuth$: Observable<boolean> = this._isAuth$.asObservable()
   constructor() {}

   checkAuth() {
      this._isAuth$.next(!!this.storageData)
      return !!this.storageData
   }

   setStorageUserData(data: any) {
      this.storageUser$.next(data)
      localStorage.setItem(ELocalStorage.USER_DATA, JSON.stringify(data))
   }
}
