<loading></loading>
<div class="grid-wrapper radius" [class.full]="!showSideBar">
   <div class="side" *ngIf="showSideBar">
      <sidemenu></sidemenu>
   </div>
   <div class="content">
      <h1 *ngIf="!showTitle">
         Dashboard<small>{{ titleText }}</small>
      </h1>
      <router-outlet></router-outlet>
   </div>
</div>
