/* istanbul ignore file */
import {
   Component,
   EventEmitter,
   Input,
   OnChanges,
   OnDestroy,
   OnInit,
   Output,
   ViewChild,
} from '@angular/core'
import {
   ControlContainer,
   ControlValueAccessor,
   FormControl,
   FormControlDirective,
   NG_VALUE_ACCESSOR,
} from '@angular/forms'
import { Subscription } from 'rxjs'

@Component({
   selector: 'inputs',
   templateUrl: './inputs.component.html',
   styleUrls: ['./inputs.component.scss'],
   providers: [
      {
         provide: NG_VALUE_ACCESSOR,
         multi: true,
         useExisting: InputsComponent,
      },
   ],
})
export class InputsComponent implements ControlValueAccessor, OnInit, OnDestroy, OnChanges {
   @ViewChild(FormControlDirective, { static: true }) formControlDirective: FormControlDirective

   @Input() formControl: FormControl
   @Input() NameformControl: string = ''
   @Input() placeholder: string = ''
   @Input() label: string = ''
   @Input() input_labels: { label: string; checked?: boolean; value: any }[]
   @Input() i_mask: string = ''
   @Input() thousandSeparator: string = ''
   @Input() prefix: string = ''
   @Input() icon: string = ''
   @Input() type: string
   @Input() hint: string
   @Input() value: any
   @Input() margin: string
   @Input() readonly: boolean
   @Input() maxlength: number = 0
   @Input() loading: boolean = false
   @Input() submitted: boolean = false
   @Input() input_type:
      | 'input'
      | 'radio'
      | 'textarea'
      | 'select'
      | 'password-valid'
      | 'toggle'
      | 'slider'
      | 'input-currency'
   @Input() setErros: any
   @Input() rows: any
   @Input() checked: boolean

   @Output() focusOut = new EventEmitter<any>()
   @Output() toggleEvent = new EventEmitter<any>()
   public passwordStrength: number = 0
   public subscriptions: Subscription[] = []
   public slideVal: number
   public valSum: number
   public errorMessageResource: any = {
      required: 'Campo obrigatório',
      minlength: 'A senha deve possuir ao menos 6 dígitos',
      noUpperCase: 'A senha deve ter ao menos uma letra maiúscula',
      noLowercase: 'A senha deve ter ao menos uma letra minúscula',
      noNumber: 'A senha deve ter ao menos um número',
      noSpecial: 'A senha deve ter ao menos um caractere especial',
   }
   public options = {
      align: 'right',
      prefix: 'R$ ',
      allowNegative: false,
      allowZero: true,
      decimal: ',',
      precision: 2,
      suffix: '',
      thousands: '.',
   }

   constructor(private controlContainer: ControlContainer) {}

   get control() {
      return this.formControl || this.controlContainer.control?.get(this.NameformControl)
   }

   ngOnChanges() {
      if (this.input_type === 'slider') {
         this.slideVal = this.value
         this.valSum = this.sumVal(this.slideVal, 0, 500)
      }
   }

   ngOnInit() {
      if (this.input_type === 'password-valid') {
         this.subscriptions.push(
            this.control.valueChanges.subscribe(() => {
               const value: string = this.control?.value

               let progress: number = 0
               progress += +!!value.match(/[0-9]/) && +!!value.match(/[^A-Za-z 0-9]/g)
               progress += +!!value.match(/[A-Z]/) && +!!value.match(/[a-z]/)
               progress += +(value.length >= 8)
               this.passwordStrength = progress || 1
            }),
         )
      }
   }

   ngOnDestroy() {
      this.subscriptions.forEach(s => s.unsubscribe())
   }

   registerOnTouched(fn: any): void {
      this.formControlDirective?.valueAccessor?.registerOnTouched(fn)
   }

   registerOnChange(fn: any): void {
      this.formControlDirective?.valueAccessor?.registerOnChange(fn)
   }

   writeValue(obj: any): void {
      this.formControlDirective?.valueAccessor?.registerOnChange(obj)
   }

   setDisabledState(isDisabled: boolean): void {
      this.formControlDirective?.valueAccessor?.setDisabledState?.(isDisabled)
   }

   attrConf(input: HTMLElement) {
      if (this.maxlength <= 0) {
         input.removeAttribute('maxlength')
      }
   }

   setVal(ev: any) {
      this.slideVal = ev.target.value
      const value = this.sumVal(this.slideVal, ev.target.min, ev.target.max)
      ev.target.style.background =
         'linear-gradient(to right, #1251ff 0%, #1251ff ' +
         value +
         '%, #fff ' +
         value +
         '%, white 100%)'
   }

   sumVal(slide: number, min: number, max: number) {
      return ((slide - min) / (max - min)) * 100
   }
}
